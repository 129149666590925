body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Raleway', sans-serif;
}

.checkbox-xl {
  padding-top: 23px;
  padding-left: 23px;
}